import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"

import li_logo from "../images/LI_Logo.png"
import { Container } from "react-bootstrap"

const Header = ({ siteTitle }) => (
  <header
    className="bg-dark"
    style={{ height: "50px", marginBottom: "50px 0 0" }}
  >
    <Navbar as="div" expand="md" fixed="top" bg="dark" variant="dark">
      <Navbar.Toggle aria-controls="navbarResponsive" />

      <Navbar.Brand href="/">Luk Vermeulen</Navbar.Brand>

      <Nav className="order-md-3">
        <Navbar.Text className="align-items-center">
          <a
            target="_blank"
            className="align-self-center"
            href="http://www.linkedin.com/in/luk-vermeulen"
          >
            <img src={li_logo} alt="LinkedIn logo" />
          </a>
        </Navbar.Text>
      </Nav>

      <Navbar.Collapse id="navbarResponsive" className="order-md-2">
        <Nav as="ul" className="mr-auto">
          <Nav.Item as="li">
            <Link to="/work" className="nav-link" activeClassName="active">
              Work
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/about" className="nav-link" activeClassName="active">
              About
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/contact" className="nav-link" activeClassName="active">
              Contact
            </Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
