import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const Footer = () => (
  <Container>
    <footer className="text-center">
      <Row>
        <Col>
          <p>Copyright © Lukas Vermeulen</p>
        </Col>
      </Row>
    </footer>
  </Container>
)

export default Footer
